<template>
  <div @click="() => (openModal = true)" class="cursor-pointer">
    <span class="material-symbols-outlined font-[10px]"> edit </span>
  </div>
  <v-dialog v-model="openModal">
    <div
      style="overflow-y: auto"
      class="bg-white w-[600px] relative max-h-[700px] overflow-y-srcoll rounded flex flex-col items-center gap-3 py-2 px-4 mx-auto edit-meal-price"
    >
      <!-- <div class="flex justify-center border w-100  items-center"> -->
      <Text :size="24" weight="600" class="">
        {{ meal?.name }}
      </Text>
      <div class="w-[40px] mx-auto text-right absolute right-2 top-3">
        <span
          @click="() => (openModal = false)"
          class="text-dark bg-white font-large cursor-pointer rounded-circle material-symbols-outlined"
        >
          cancel
        </span>
      </div>
      <!-- </div> -->

      <div class="parent-container">
        <div class="">
          <Text weight="400" :size="17">Description: </Text>
          <div class="input_field">
            <!-- {{ meal?.items }} -->
            <textarea
              type="text"
              cols="10"
              class="p-2 border border-1 border-black outline-none rounded"
              style="width: 100%"
              :defaultValue="meal.items || 'No note'"
              @input="
                (e) =>
                  editMealDetail(e.target.value, meal, null, 'items', 'string')
              "
            />
          </div>
        </div>
      </div>
      <div class="parent-container">
        <div class="flex items-center gap-2">
          <Text weight="400" style="display: block" class="pt-1" :size="17">
            <label for="hidden"> Hide: </label>
          </Text>
          <input
            id="hidden"
            type="checkbox"
            class="cursor-pointer"
            :defaultChecked="Boolean(meal?.hidden)"
            @input="
              (e) => editMealDetail(e.target.checked, meal, null, 'hidden')
            "
          />
          <Text weight="400" class="ml-3" :size="17">
            State: {{ meal?.hidden ? "hidden" : "shown" }}
          </Text>
        </div>
      </div>
      <!-- unit price edit––––––––––––––––––––––––––––––––––––------------------------------------ -->
      <div class="parent-container" v-if="meal.amount || meal?.prices?.length">
        <div class="input-container mt-2 items-center">
          <Text weight="400" :size="17">Unit </Text>
          <div class="flex gap-2">
            <div v-if="meal.calories" class="input_field">
              <span>cal</span>
              <input
                type="number"
                :defaultValue="meal.calories"
                @input="
                  (e) => {
                    editMealDetail(
                      e.target.value,
                      meal,
                      null,
                      'calories',
                      null
                    );
                  }
                "
              />
            </div>
            <div class="input_field">
              <span>₦</span>
              <input
                type="number"
                :defaultValue="toNum(meal.amount)"
                @input="
                  (e) => {
                    editMealDetail(
                      addComma(e.target.value),
                      meal,
                      null,
                      'amount',
                      meal
                    );
                    if (meal?.from)
                      editMealDetail(
                        addComma(e.target.value),
                        meal,
                        null,
                        'from'
                      );
                  }
                "
              />
            </div>
          </div>
        </div>
      </div>

      <!-- mealpoint  edit––––––––––––––––––––––––––––––––––––------------------------------------ -->
      <div class="parent-container" v-if="meal.point">
        <div class="input-container mt-2 items-center">
          <Text weight="400" :size="17">Meal point </Text>
          <div class="input_field">
            <span>₦</span>
            <input
              type="number"
              :defaultValue="meal.point"
              @input="
                (e) => editMealDetail(e.target.value, meal, null, 'point')
              "
            />
          </div>
        </div>
      </div>

      <!-- selectedprices price edit––––––––––––––––––––––––––––––––––––------------------------------------ -->
      <div class="parent-container" v-if="meal.prices?.length">
        <Text class="list-"> Price(s) </Text>
        <div class="flex flex-col gap-2">
          <div
            v-for="(price, index) in meal.prices"
            :key="price.price"
            class="input-container items-center"
          >
            <Text weight="400" :size="17">{{ price.item }} </Text>
            <div class="input_field">
              <span>₦</span>
              <input
                type="number"
                disabled
                :defaultValue="toNum(price.price)"
                @input="
                  (e) =>
                    editMealDetail(
                      addComma(e.target.value),
                      meal,
                      index,
                      `prices/${index}/price`
                    )
                "
              />
            </div>
          </div>
        </div>
      </div>
      <!-- types price edit––––––––––––––––––––––––––––––––––––------------------------------------ -->
      <div class="parent-container" v-if="meal.types?.length">
        <Text class="list-"> Types(s) </Text>
        <!-- <div class="flex flex-col gap-2"> -->
        <draggable
          @end="() => onDrag(meal, 'types')"
          v-model="meal.types"
          class="flex flex-col gap-2"
        >
          <template #item="{ element: type, index: index }">
            <div :key="type.price" class="input-container items-center">
              <Text weight="400" :size="17">{{ type.item }} </Text>
              <div class="input_field">
                <span>₦</span>
                <input
                  type="number"
                  :defaultValue="toNum(type.price)"
                  @input="
                    (e) =>
                      editMealDetail(
                        addComma(e.target.value),
                        meal,
                        index,
                        `types/${index}/price`,
                        type
                      )
                  "
                />
              </div>
            </div>
          </template>
        </draggable>
        <!-- </div> -->
      </div>
      <!-- extra price edit––––––––––––––––––––––––––––––––––––------------------------------------ -->
      <div class="parent-container" v-if="meal.extra?.length">
        <Text class="list-"> Extra(s) </Text>
        <!-- <div class="flex flex-col gap-2"> -->
        <draggable
          @end="() => onDrag(meal, 'extra')"
          v-model="meal.extra"
          class="flex flex-col gap-2"
        >
          <template #item="{ element: item, index: index }">
            <div class="input-container items-center" :key="item.amount">
              <Text weight="400" :size="17">{{ item.name }} </Text>
              <div class="input_field">
                <span>₦</span>
                <input
                  type="number"
                  :defaultValue="toNum(item.amount)"
                  @input="
                    (e) =>
                      editMealDetail(
                        addComma(e.target.value),
                        meal,
                        index,
                        `extra/${index}/amount`,
                        item
                      )
                  "
                />
              </div>
            </div>
          </template>
        </draggable>
        <!-- </div> -->
      </div>
      <!-- customize price edit––––––––––––––––––––––––––––––––––––------------------------------------ -->
      <div class="parent-container" v-if="meal.customize?.length">
        <Text class="list-"> Customize </Text>

        <draggable
          @end="() => onDrag(meal, 'customize')"
          v-model="meal.customize"
          class="flex flex-col gap-2"
        >
          <!-- <div class="flex flex-col gap-2"> -->
          <template #item="{ element: item, index: index }">
            <div :key="item.amount" class="input-container items-center">
              <Text weight="400" :size="17">{{ item.name }} </Text>
              <div class="input_field">
                <span>₦</span>
                <input
                  type="number"
                  :defaultValue="toNum(item.amount)"
                  @input="
                    (e) =>
                      editMealDetail(
                        addComma(e.target.value),
                        meal,
                        index,
                        `customize/${index}/amount`,
                        item
                      )
                  "
                />
              </div>
            </div>
          </template>
          <!-- </div> -->
        </draggable>
      </div>
      <!-- options price edit––––––––––––––––––––––––––––––––––––------------------------------------ -->
      <div class="parent-container" v-if="meal.options">
        <div v-for="option in Object.entries(meal?.options)" :key="option[0]">
          <Text class="list-"> {{ option[0] }} </Text>
          <!-- <div class="flex flex-col gap-2"> -->
          <draggable
            @end="() => onDrag(meal, `options?.${option[0]}`)"
            :list="option[1]"
            class="flex flex-col gap-2"
          >
            <template #item="{ element: item, index: index }">
              <div :key="item.amount" class="input-container items-center">
                <Text weight="400" :size="17">{{ item.name }} </Text>
                <div class="flex gap-3">
                  <!-- options calories -->
                  <div class="input_field" v-if="item.calories">
                    <span>cal</span>
                    <input
                      type="number"
                      :defaultValue="item.calories"
                      @input="
                        (e) =>
                          editMealDetail(
                            e.target.value,
                            meal,
                            index,
                            `options/${option[0]}/${index}/calories`,
                            null
                          )
                      "
                    />
                  </div>
                  <!-- options limit -->
                  <div class="input_field" v-if="item.limit">
                    <span>limit</span>
                    <input
                      type="number"
                      :defaultValue="item.limit"
                      @input="
                        (e) =>
                          editMealDetail(
                            e.target.value,
                            meal,
                            index,
                            `options/${option[0]}/${index}/limit`,
                            item,
                            true,
                            false
                          )
                      "
                    />
                  </div>
                  <!-- options price -->
                  <div class="input_field">
                    <span>₦</span>
                    <input
                      type="number"
                      :defaultValue="toNum(item.amount)"
                      @input="
                        (e) =>
                          editMealDetail(
                            addComma(e.target.value),
                            meal,
                            index,
                            `options/${option[0]}/${index}/amount`,
                            item
                          )
                      "
                    />
                  </div>
                </div>
              </div>
            </template>
          </draggable>
          <!-- </div> -->
        </div>
      </div>
      <!-- extra_portion price edit––––––––––––––––––––––––––––––––––––------------------------------------ -->
      <div class="parent-container" v-if="meal?.extra_portion">
        <div class="input-container mt-2 items-center">
          <Text weight="400" :size="17">{{ meal?.extra_portion?.name }} </Text>
          <div class="input_field">
            <span>₦</span>
            <input
              type="number"
              :defaultValue="toNum(meal?.extra_portion?.amount)"
              @input="
                (e) =>
                  editMealDetail(
                    addComma(e.target.value),
                    meal,
                    null,
                    'extra_portion/amount'
                  )
              "
            />
          </div>
        </div>
      </div>

      <div class="parent-container">
        <div class="input-container h-12 items-center">
          <Text weight="400" :size="17">Is popular</Text>
          <div class="input_field">
            <v-switch
              v-model="meal.isPopular"
              :onchange="
                (e) =>
                  editMealDetail(
                    e.target.checked,
                    meal,
                    null,
                    'isPopular',
                    meal,
                    true
                  )
              "
              color="blue"
            ></v-switch>
          </div>
        </div>
        <div class="input-container h-12 items-center">
          <Text weight="400" :size="17">Highly rated</Text>
          <div class="input_field">
            <v-switch
              v-model="rated"
              :onchange="
                (e) =>
                  editMealDetail(
                    e.target.checked ? 'high' : '',
                    meal,
                    null,
                    'rated',
                    meal,
                    true
                  )
              "
              color="blue"
            ></v-switch>
          </div>
        </div>
      </div>
      <div class="parent-container"></div>

      <button
        @click="saveChanges"
        :disabled="loading"
        :class="`px-3 py-2 rounded  text-white ${
          loading ? 'bg-light' : 'ue-btn'
        } my-3`"
      >
        {{ loading ? "Loading..." : "Save" }}
      </button>
    </div>
  </v-dialog>
</template>

<script setup>
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import { set, ref as dbRef, getDatabase } from "firebase/database";
import { ref, watchEffect } from "vue";
import { useToast } from "vue-toast-notification";
import { getAllOccurancesOfItem } from "./getAllOccurancesOfItem";
import { meals } from "@/views/store/meals";
import { httpsCallable } from "firebase/functions";
import { projectFunctions } from "@/firebase/config";
import { getMultiplePrices } from "./getMultiplePrices";
import draggable from "vuedraggable";
import { computed } from "vue";

const openModal = ref(false);
const meal = ref({});
const changes = ref({});
const loading = ref(true);
const mealsEditting = ref([]);
const toNum = (price) => {
  // console.log(price, typeof price);
  if (typeof price === "string") {
    return parseInt(price?.replaceAll(",", ""));
  } else {
    return price;
  }
};
const props = defineProps({
  meal: Object,
  isReward: Boolean,
});

const addComma = (number) => {
  return Number(number)?.toLocaleString();
};
const rated = ref(props.meal?.rated === "high");

const onDrag = (mealArranged, key) => {
  loading.value = false;
  const basePath = `/restaurants/${mealArranged.restaurant_name}/food/${mealArranged.food_category}/${mealArranged.index_in_firebase}/`;
  const newArrangement = eval(`meal.value.${key}`);
  const path = basePath + key?.replaceAll("?.", "/");
  console.log(path, newArrangement);
  changes.value[path] = newArrangement;
};

const editMealDetail = (
  value,
  meal,
  priceIndex,
  type,
  item,
  use_occurances = true,
  use_multi_keywords = true
) => {
  // console.log({ value, meal, priceIndex, type, item }, props.isReward);
  if (!props.isReward) {
    const basePath = `/restaurants/${meal.restaurant_name}/food/${meal.food_category}/${meal.index_in_firebase}/`;
    const path = basePath + type;
    changes.value[path] = value;

    if (use_occurances) {
      const occurances = getAllOccurancesOfItem(
        meals.state.value,
        item,
        use_multi_keywords
      );

      if (type.includes("amount")) {
        if (occurances?.occured_in?.length && type !== "extra_portion/amount") {
          occurances?.occured_in.forEach((element) => {
            const path = element.amount_path;
            changes.value[path] = (
              parseInt(value?.replaceAll(",", "")) * element?.multiply
            ).toLocaleString();
          });
        }
        const multiPrices = getMultiplePrices(
          meals.state.value,
          item || meal,
          value,
          priceIndex
        );
        if (multiPrices?.length && type !== "extra_portion/amount") {
          multiPrices.forEach((element) => {
            const unit_amount_path = element.unit_amount_path;
            const item_price_path = element.item_price_path;

            const unit_amount_value = element.unit_amount?.toLocaleString();
            const item_price_value = element.item_price_value?.toLocaleString();

            changes.value[unit_amount_path] = unit_amount_value;
            changes.value[item_price_path] = item_price_value;
          });
        }
      }
      if (type === "rated") {
        occurances.occured_in.forEach((element) => {
          if (element?.ratePath) changes.value[element?.ratePath] = value;
        });
      }
      if (type === "isPopular") {
        occurances.occured_in.forEach((element) => {
          if (element?.isPopularPath)
            changes.value[element?.isPopularPath] = value;
        });
      }
      if (type?.includes("limit")) {
        occurances.occured_in.forEach((element) => {
          if (element?.limit_path) changes.value[element?.limit_path] = value;
        });
      }
    }
  } else {
    const basePath = `/restaurants/${meal.restaurant_name}/reward/${meal.index_in_firebase}/`;
    const path = basePath + type;
    changes.value[path] = toNum(value);
    mealsEditting.value;
  }

  loading.value = false;
};

const toast = useToast({ position: "top-right" });

const saveChanges = async () => {
  let success = 0,
    errors = [];
  const list_of_changes = Object.entries(changes.value);
  toast.info("Please wait while all item occurences update");

  if (!list_of_changes.length) {
    openModal.value = false;
    return;
  } /*  */
  loading.value = true;

  const multipleUpdates = httpsCallable(projectFunctions, "multipleUpdates");
  const updates = list_of_changes.map((change) => ({
    path: change[0],
    value: change[1],
  }));
  console.log({ updates });
  await multipleUpdates({
    updates,
  });
  toast.success("Updates uploaded, please check prices in a few minutes.");
  changes.value = {};
  loading.value = false;
  openModal.value = false;
};

watchEffect(() => {
  openModal.value;
  meal.value = props.meal;
});
</script>

<style lang="scss" scoped>
.edit-meal-price {
  .parent-container {
    width: 100%;
    .list- {
      font-size: 17px;
      font-weight: 600;
    }
    .input-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      cursor: grab;

      p {
        // width: ;
      }
      .input_field {
        display: flex;
        gap: 3px;
        align-items: center;
      }
      input {
        border: 1px solid black;
        padding: 3px 6px;
        width: 60px;
        border-radius: 8px;
        outline: none;
        text-align: right;
      }
    }
  }
}
</style>