export const getAllOccurancesOfItem = (meals = [], meal, use_multi_keywords = true) => {

    // console.log(meal)
    let occured_in = []
    const multi_keywords = use_multi_keywords ? ['single ', 'double ', 'extra '] : []
    const extra_keys = ['extra', 'types', 'customize']

    const singulars = use_multi_keywords ? ['Single ', 'Extra '] : []
    const doubles = use_multi_keywords ? ['Double '] : []

    const mealContainsSingles = singulars.some(singular => meal?.name?.includes(singular))
    const mealContainsDoubles = doubles.some(double => meal?.name?.includes(double))

    const check = (item,) => {
        const remove_multi_keyword = (name = '') => {
            let extra_name = name
            multi_keywords.forEach((keyword) => {
                extra_name = extra_name.replace(keyword, '')
            })
            return extra_name
        }
        const item_name = remove_multi_keyword(item?.name?.toLowerCase())
        const meal_name = remove_multi_keyword(meal?.name?.toLowerCase())
        // if (item_name === meal_name) console.log(({ item_name, meal_name, item }))
        return item_name === meal_name
    }

    meals.forEach((meal_item, index) => {
        if (check(meal_item)) {
            // console.log("meal",meal_item)
            const stoplist_path = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/available`);
            const amount_path = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/amount`);
            const ratePath = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/rated`);
            const isPopularPath = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/isPopular`);
            const self = meal_item
            occured_in.push({ meal_item, self, stoplist_path, amount_path, name: meal_item?.name, multiply: 1, ratePath, isPopularPath })
        }

        extra_keys.forEach((extra_key) => {
            if (meal_item[extra_key]) {
                meal_item[extra_key]?.forEach((occurance, innerIndex) => {
                    if (check(occurance)) {
                        const stoplist_path = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/${extra_key}/${innerIndex}/available`)
                        const amount_path = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/${extra_key}/${innerIndex}/amount`)
                        const self = occurance
                        let multiply = 1
                        //if meal edited contains 'Single ','Extra ', '1 ' then multiply 'Double ', '2 ' amount by 2
                        if (mealContainsSingles) {
                            if (doubles.some(double => occurance?.name?.includes(double))) multiply = 2
                        }
                        //if meal edited contains 'Double ', '2 ' then multiply 'Single ','Extra ', '1 ' amount by 0.5
                        if (mealContainsDoubles) {
                            if (singulars.some(singular => occurance?.name?.includes(singular))) multiply = 0.5
                        }
                        occured_in.push({ meal_item, extra_key, innerIndex, stoplist_path, self, amount_path, name: occurance?.name, multiply, ratePath: '', isPopularPath: '' })
                    }
                })
            }
        })

        if (meal_item?.options) {
            const opts = Object.entries(meal_item.options || {})
            opts.forEach(option => {
                option[1].forEach((item, innerIndex) => {
                    const occurance = item
                    if (check(item)) {
                        const stoplist_path = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/options/${option[0]}/${innerIndex}/available`)
                        const amount_path = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/options/${option[0]}/${innerIndex}/amount`)
                        const limit_path = (`/restaurants/${meal_item.restaurant_name}/food/${meal_item.food_category}/${meal_item.index_in_firebase}/options/${option[0]}/${innerIndex}/limit`)
                        const self = occurance
                        let multiply = 1
                        //if meal edited contains 'Single ','Extra ', '1 ' then multiply 'Double ', '2 ' amount by 2
                        if (mealContainsSingles) {
                            if (doubles.some(double => occurance?.name?.includes(double))) multiply = 2
                        }
                        //if meal edited contains 'Double ', '2 ' then multiply 'Single ','Extra ', '1 ' amount by 0.5
                        if (mealContainsDoubles) {
                            if (singulars.some(singular => occurance?.name?.includes(singular))) multiply = 0.5
                        }
                        occured_in.push({ meal_item, extra_key: `options ${option[0]}`, innerIndex, stoplist_path, self, amount_path, name: item?.name, multiply, ratePath: '', isPopularPath: '', limit_path })
                    }
                })


            });
        }
    })


    if (occured_in.length > 1) return ({ occured_in, meal })

}